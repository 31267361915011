import React from 'react';
import CurrencySwitcher from '../Header/currencyswitcher/CurrencySwitcher';

export default function ShopIBookProduct(props) {
  const { className, product } = props;
  const minVariantPrice = product?.priceRange?.minVariantPrice;
  // const shortDescription = !!product?.description ? product?.description.split('.') : [''];

  const appleURL = product?.metafields && product?.metafields.find((elm) => elm.key === 'ibook_url');
  const uri = appleURL?.value || '';

  return (
    <div className={className}>
      <div
        className="list-figure bgImage"
        style={{ backgroundImage: `url(${product.featuredImage.gatsbyImageData.images.fallback.src})` }}
      ></div>
      <div className="book-detail">
        <h6>{product?.title}</h6>
        <hr />
        <div className="product-description">
          <div className="line-clamp" dangerouslySetInnerHTML={{ __html: `${product.descriptionHtml}` }} />
        </div>
        <div className="product-description"></div>
        <div className="cart-option">
          <span className="list-title">
            <CurrencySwitcher price={parseFloat(minVariantPrice?.amount).toFixed(2)} />
          </span>
          <a href={uri} className="btn-buy">
            Buy on Apple Books
          </a>
        </div>
      </div>
    </div>
  );
}
