import { Link } from 'gatsby';
import React, { useState, useEffect, useRef } from 'react';

import iconChevronDown from '../../images/icon-chevron-down.svg';

export default function Dropdown({ hideLabel, options }) {
  const [showDropdown, setShowDropdown] = useState(false);
  const node = useRef();
  const handleClick = (e) => {
    if (node.current.contains(e.target)) {
      return;
    }
    setShowDropdown(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  return (
    <div ref={node} className="select-single d-flex align-items-center">
      {!hideLabel && <label className="mg-r-20">Other</label>}
      <div className="select-box select-box-single">
        <div className="select-box_value select-box-single_value" onClick={() => setShowDropdown(!showDropdown)}>
          <p className="select-box_input-text select-box-single_input-text">Select Categories</p>
          <img className="select-box_icon" src={iconChevronDown} alt="Arrow Icon" aria-hidden="true" />
        </div>
        <ul className={`select-box_list select-box-single_list ${showDropdown ? 'showMenu' : ''}`}>
          {options.map((option, index) => (
            <Link to={`/product-category/${option.toLowerCase()}`}>
              <li key={index} className="select-box_option">
                {option}
              </li>
            </Link>
          ))}
        </ul>
      </div>
    </div>
  );
}
