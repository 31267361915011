import React from 'react';
import { Link } from 'gatsby';

import iconView from '../../images/icon-view.svg';
import CurrencySwitcher from '../Header/currencyswitcher/CurrencySwitcher';

export default function ShopProduct(props) {
  const { product, className } = props;

  const minVariantPrice = product?.priceRange?.minVariantPrice;

  const uri = product.url;

  const content = () => {
    return (
      <>
        <div className="btn btn-view">
          <img src={iconView} alt="View Product" />
        </div>
      </>
    );
  };

  return (
    <Link to={`/product/${uri}`} className={className}>
      <div
        className="list-figure bgImage"
        style={{ backgroundImage: `url(${props.product.featuredImage.gatsbyImageData.images.fallback.src})` }}
      >
        {content()}
      </div>

      <div className="list-content">
        <span className="list-title">{props.product.title}</span>
        <span className="list-price">
          {' '}
          <CurrencySwitcher price={parseFloat(minVariantPrice?.amount).toFixed(2)} />
        </span>
      </div>
    </Link>
  );
}
